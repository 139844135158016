export interface ProxyWatch<T extends object> {
  // eslint-disable-next-line no-use-before-define
  onSetValue(proxy: WatchingProxy<T>, target: T, key: string, value: any): void
}

/**
 * A transparent proxy which allows tracking any changes
 * made on the proxied object.
 */
export class WatchingProxy<T extends object> {
  public readonly proxy: T

  private _disabled = false
  private readonly _unwatchedCalls = new Map<
    string,
    {
      proxy: WatchingProxy<T>
      target: T
      value: any
    }
  >()

  public get watchDisabled () {
    return this._disabled
  }

  /**
   * If <code>disabled</code> is set to <code>true</code>,
   * stops calling watchers on every property change,
   * and silently collect last changes.
   *
   * If <code>disabled</code> is <code>false</code> fire
   * any collected changes (only the last change per property),
   * and enables change tracking.
   *
   * @param disabled
   */
  public set watchDisabled (disabled: boolean) {
    if (!disabled && this._unwatchedCalls.size) {
      for (const watch of this.watches) {
        for (const key of this._unwatchedCalls.keys()) {
          const call = this._unwatchedCalls.get(key)!
          watch.onSetValue(call.proxy, call.target, key, call.value)
        }
      }
    }
    this._disabled = disabled
    this._unwatchedCalls.clear()
  }

  constructor (
    private readonly target: T,
    private readonly watches: ProxyWatch<T>[] = [],
    keyPrefix = '',
    originalProxy?: WatchingProxy<T>,
    originalTarget?: T
  ) {
    this.proxy = new Proxy<T>(target, {
      get: (t: any, key: string) => {
        if (typeof t[key] === 'object' && t[key] !== null) {
          return new WatchingProxy(
            t[key],
            watches,
            keyPrefix ? `${keyPrefix}.${key}` : key,
            originalProxy ?? this,
            originalTarget ?? target
          ).proxy
        } else {
          return t[key]
        }
      },
      set: (t, key: string, value) => {
        Reflect.set(t, key, value)
        for (const watch of watches) {
          const proxy = originalProxy ?? this
          const propertyKey = keyPrefix ? `${keyPrefix}.${key}` : key
          if (!proxy._disabled) {
            watch.onSetValue(proxy, originalTarget ?? target, propertyKey, value)
          } else {
            proxy._unwatchedCalls.set(propertyKey, { proxy, target: originalTarget ?? target, value })
          }
        }
        return true
      }
    })
  }

  public addWatch (watch: ProxyWatch<T>) {
    const i = this.watches.indexOf(watch)
    if (i < 0) {
      this.watches.push(watch)
    }
  }

  unproxy (): T {
    return this.target
  }
}
