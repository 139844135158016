import jwtDecode, { JwtPayload } from 'jwt-decode'
import { Router, RouteRecordRaw } from 'vue-router'

import type { UserModule } from '@/UserModule'
import { api } from '@/api/api'
import {
  getGoogleAuthInfo,
  getClareityAuthInfo,
  authenticateWithClareity,
  authenticateWithGoogle,
  refreshToken as doRefreshToken,
  getMyProfile,
  getMicrosoftAuthInfo,
  authenticateWithMicrosoft,
  authenticateWithDynaConnect,
  getDynaConnectAuthInfo,
  hashAuth
} from '@/api/apiCalls'
import {
  AuthenticationResponse,
  AuthInfo,
  UserCompanyPermissionInfo,
  UserPermissionInfo,
  UserTeamPermissionInfo
} from '@/api/apiTypes'
import { rollbar } from '@/modules/rollbar'
import { hashAuthWithQuery, isRequiredHashAuthParamsInQuery, purifyHashAuthQueryParams } from '@/utils/auth'
import { groupBy, unwrapArray } from '@/utils/utils'

type AuthProviderFn = (args: {
  code: string
  state: string
  redirectUrl: string
}) => Promise<AuthenticationResponse>

export interface JwtUserPayload extends JwtPayload {
  role: string
  session_id: string
  mlsid?: string
}

export const accessToken = useLocalStorage<string>('accessToken', '')
// export const accessToken = ref<string>('')
export const accessTokenJWT = ref<JwtUserPayload>()
watch(accessToken, (token) => {
  accessTokenJWT.value = token ? jwtDecode<JwtUserPayload>(token) : undefined
}, { immediate: true })
// @ts-ignore
window.accessTokenJWT = accessTokenJWT

export const refreshToken = useLocalStorage<string>('refreshToken', '')
// export const refreshToken = ref<string>('')
export const refreshTokenJWT = ref<JwtUserPayload>()
watch(refreshToken, (token) => {
  refreshTokenJWT.value = token ? jwtDecode<JwtUserPayload>(token) : undefined
}, { immediate: true })
// @ts-ignore
window.refreshTokenJWT = refreshTokenJWT

const logToken = (token: JwtUserPayload, label: string) => {
  console.log(`${label}, expires:`, new Date(token.exp! * 1000).toString(), 'sessionId:', token.session_id)
}

watch(accessTokenJWT, (token) => {
  if (!token) return
  logToken(token, 'accessToken')
}, { immediate: true })

watch(refreshTokenJWT, (token) => {
  if (!token) return
  logToken(token, 'refreshToken')
}, { immediate: true })

const fiveMins = 5 * 60
export const isTokenExpired = (token: JwtPayload | undefined) => {
  if (!token || !token.exp) return true
  return Date.now() / 1000 + fiveMins > token.exp
}

const refreshTokens = () => {
  console.log('refreshing tokens with:')
  logToken(refreshTokenJWT.value!, 'refreshToken')
  return doRefreshToken({ refreshToken: refreshToken.value }, { suppressedCodes: [401] }).then((data) => {
    console.log('tokens refreshed')
    accessToken.value = data.accessToken
    refreshToken.value = data.refreshToken
  }).catch((error) => console.warn(error))
}

const hashRefreshTokens = ({ ud, ts, hs }: Record<'ud' | 'ts' | 'hs', string>) => {
  console.log('refreshing hash tokens with:')
  return hashAuth({ ud, ts, hs }, { suppressedCodes: [401] })
    .then((data) => {
      console.log('tokens refreshed')
      accessToken.value = data.accessToken
      refreshToken.value = data.refreshToken
    }).catch((error) => console.warn(error))
}

let refreshPromise: Promise<void> | undefined

/**
 * Build function to get auth info for provider
 */
const buildGetAuthInfo = (
  redirectPath: string,
  getAuthInfo: (params: { redirectUrl: string }) => Promise<AuthInfo>
) => () => {
  const redirectUrl = location.origin + redirectPath
  return getAuthInfo({ redirectUrl }).then((data) => {
    window.location.href = data.authUrl
    return new Promise((resolve) => setTimeout(resolve, 1000000)) // almost infinite promise
  })
}

/**
 * Build Sign In route for auth provider
 */
const buildSignInRoute = (path: string, name: string, authProvider: AuthProviderFn): RouteRecordRaw => ({
  path,
  name,
  component: () => null,
  beforeEnter: (to, from, next) => {
    const code = unwrapArray(to.query.code)
    const state = unwrapArray(to.query.state)
    if (!code || !state) {
      console.error('No code, or state provided in the query', to.query)
      return next({ name: 'Auth' })
    }
    const redirectUrl = location.origin + path
    authProvider({ code, state, redirectUrl }).then((data) => {
      accessToken.value = data.accessToken
      refreshToken.value = data.refreshToken
      if (returnFullPath.value) {
        const _path = returnFullPath.value
        returnFullPath.value = ''
        next(_path)
      } else {
        next({ name: 'Home' })
      }
    }).catch((e) => {
      console.error(e)
      next({ name: 'Auth' })
    })
  }
})

const smartMLSSignInRedirectPath = '/signin-dynaconnect'
const googleSignInRedirectPath = '/signin-google'
const msSignInRedirectPath = '/signin-ms'
const clareitySignInRedirectPath = '/clareity'

export const signInWithSmartMLS = buildGetAuthInfo(smartMLSSignInRedirectPath, getDynaConnectAuthInfo)
export const signInWithGoogle = buildGetAuthInfo(googleSignInRedirectPath, getGoogleAuthInfo)
export const signInWithMs = buildGetAuthInfo(msSignInRedirectPath, getMicrosoftAuthInfo)
export const signInWithClareity = buildGetAuthInfo(clareitySignInRedirectPath, getClareityAuthInfo)

const returnFullPath = useLocalStorage('authReturnPath', '')

export const install: UserModule = ({ router }) => {
  router.beforeEach((to, _, next) => {
    const useAuth = to.matched.some((m) => m.meta.useAuth)
    if (!useAuth) return next()

    if (isRequiredHashAuthParamsInQuery(to.query)) {
      return hashAuthWithQuery(to)
        .then(purifyHashAuthQueryParams)
        .then(next)
        .catch(() => next({ name: 'Auth' }))
    }

    if (isTokenExpired(accessTokenJWT.value)) {
      if (isTokenExpired(refreshTokenJWT.value)) {
        console.log('Token expired. Redirecting to auth page.')
        returnFullPath.value = to.fullPath
        return next({ name: 'Auth' })
      }
      if (!refreshPromise) {
        refreshPromise = refreshTokens().finally(() => {
          refreshPromise = undefined
        })
      }
      return refreshPromise.then(() => {
        next()
      }).catch((err) => {
        console.error('Failed refreshing token automatically. Redirecting to auth page.', err)
        returnFullPath.value = to.fullPath
        next({ name: 'Auth' })
      })
    }
    next()
  })

  /**
   * Refreshing tokens for widgets
   */
  api.addRequestInterceptor((req) => {
    if (router.currentRoute.value.name !== 'Widget') return req

    const ud = window.localStorage.getItem('ud')
    const ts = window.localStorage.getItem('ts')
    const hs = window.localStorage.getItem('hs')

    if (
      (isTokenExpired(accessTokenJWT.value) ||
        isTokenExpired(refreshTokenJWT.value)) &&
      ud && ts && hs && !refreshPromise
    ) {
      refreshPromise = hashRefreshTokens({ ud, ts, hs })
        .finally(() => {
          refreshPromise = undefined
        })

      return refreshPromise.then(() => {
        req.headers = {
          ...req.headers,
          Authorization: `Bearer ${accessToken.value}`
        }
        return req
      }).catch((err) => {
        console.error(err)
        throw err
      })
    }

    return req
  })

  api.addRequestInterceptor((req) => {
    if (req.url.startsWith('/auth')) return req

    if (isTokenExpired(accessTokenJWT.value)) {
      if (isTokenExpired(refreshTokenJWT.value)) {
        router.push({ name: 'Auth' })
        return req
      }
      if (!refreshPromise) {
        refreshPromise = refreshTokens().finally(() => {
          refreshPromise = undefined
        })
      }
      return refreshPromise.then(() => {
        req.headers = {
          ...req.headers,
          Authorization: `Bearer ${accessToken.value}`
        }
        return req
      }).catch((err) => {
        console.error(err)
        router.push({ name: 'Auth' })
        throw err
      })
    }
    req.headers = {
      ...req.headers,
      Authorization: `Bearer ${accessToken.value}`
    }
    return req
  })

  api.addResponseInterceptor((res) => {
    // TODO: 401 response can't be read because of CORS
    if (res.status === 401) {
      router.push({ name: 'Auth' })
    }
    return res
  })
}

export const signInRoutes: RouteRecordRaw[] = [
  buildSignInRoute(smartMLSSignInRedirectPath, 'SignInSmartMLS', authenticateWithDynaConnect),
  buildSignInRoute(googleSignInRedirectPath, 'SignInGoogle', authenticateWithGoogle),
  buildSignInRoute(msSignInRedirectPath, 'SignInMs', authenticateWithMicrosoft),
  buildSignInRoute(clareitySignInRedirectPath, 'SignInClareity', authenticateWithClareity)
]

export const useSignOut = (router: Router) => {
  return () => {
    accessToken.value = ''
    refreshToken.value = ''
    router.push({ name: 'Auth' })
  }
}

export const isUserLoading = ref(false)
export const user = computedAsync(
  () =>
    getMyProfile({ photo: true, permissions: true, loadAssisting: true, companies: true }, { suppressedCodes: [401] }).then((result) => {
      rollbar?.configure({
        payload: {
          person: {
            id: result.id, // required
            email: result.email
          }
        }
      })
      return result
    }),
  undefined,
  { lazy: true, evaluating: isUserLoading }
)

export const isSpecialUser = computed(() => user.value?.special)

export interface UserPermissionOptions {
  /**
   * Return user's own permissions only.
   */
  ownOnly?: boolean

  /**
   * Only return permissions owned by this user id.
   */
  ownerId?: number

  /**
   * Only return unique permissions.
   */
  distinct?: boolean
}

export const userPermissions = (options?: UserPermissionOptions): UserPermissionInfo[] => {
  const permissions = user.value?.permissions?.filter((p) => {
    if (options?.ownerId) {
      if (p.ownerId !== options.ownerId) {
        return false
      }
    }
    if (options?.ownOnly) {
      if (p.ownerId !== user.value?.id) {
        return false
      }
    }
    return true
  }) ?? []

  if (!options?.distinct) {
    return permissions
  }

  const groups = groupBy(permissions, (p) => {
    switch (p.type) {
      case 'mls':
      case 'association':
      case 'firm':
      case 'office':
        return p.type + (p as UserCompanyPermissionInfo).company.id
      case 'team':
        return p.type + (p as UserTeamPermissionInfo).team.id
      default:
        return p.type
    }
  })
  return groups.map((g) => g.items[0])
}
