/**
 * file is autogenerated by utils/generateApi.ts, do not edit
 */

import { CallerOptions, ResponseWithData } from '@/utils/createApi'

import { api } from './api'
import {
  MaintenanceInfo,
  MaintenanceForm,
  AuthInfo,
  AuthenticationResponse,
  GoogleAuthForm,
  ClareityAuthForm,
  MicrosoftAuthForm,
  DynaConnectAuthForm,
  TokenRefreshForm,
  HashAuthForm,
  DocumentCreatedInfo,
  DocumentForm,
  DocumentInfo,
  Id,
  DocumentProcessingStatus,
  DocumentDownloadUrl,
  DocumentImageUrl,
  TemplatesPagedList,
  TemplateInfo,
  UserInfo,
  CompanyType,
  SigningStageInfo,
  TemplateForm,
  SigningStageForm,
  TemplatesTagPagedList,
  DuplicateTemplateForm,
  TemplatePermissionList,
  TemplatePermissionsForm,
  TemplateFavoritesInfo,
  TemplateFavoritesForm,
  SigningPagedList,
  SigningInfo,
  ContactGroupInfo,
  ContactGroupItemInfo,
  ParticipantInfo,
  ContactInfo,
  SigningForm,
  ParticipantForm,
  SigningData,
  WithdrawSigningForm,
  ForwardSigningForm,
  DuplicateSigningForm,
  Id1,
  SigningCommentPagedList,
  SigningCommentInfo,
  Page,
  SigningCommentForm,
  SigningCompletedInfo,
  SigningReviewInfo,
  PhoneVerificationResponse,
  SmsVerificationForm,
  ConsentForm,
  RejectSigningForm,
  EventPagedList,
  SigningAddressPagedList,
  CompanyPagedList,
  ContactPagedList,
  ContactForm,
  BulkUpdateContactForm,
  ContactCompanyPagedList,
  ContactGroupPagedList,
  ContactGroupForm,
  BulkUpdateContactsInGroupRequest,
  ContactGroupItemForm,
  TeamsPagedList,
  UsersPagedList,
  UserSpecialPermissionForm,
  RoleInfo,
  NotificationSettingsInfo,
  NotificationSettingsForm,
  UploadedLogoInfo,
  UploadLogoInfo,
  UploadLogoForm,
  EmailAppearanceSettingsInfo,
  EmailAppearanceSettingsForm,
  GetEmailAppearancePreviewRequest,
  OverlayDefaultSettings,
  SavedInstructionsPagedList,
  SavedInstructionsInfo,
  SavedInstructionsForm,
  ClausePagedList,
  ClauseInfo,
  ClauseForm
} from './apiTypes'

const getData = <T>(res: ResponseWithData<T>): T => res.data

export const endMaintenance = (options?: CallerOptions): Promise<void> => {
  return api.delete<void>('/admin/maintenance', { ...options }).then(getData)
}

export const scheduleMaintenance = (data: MaintenanceForm, options?: CallerOptions): Promise<MaintenanceInfo> => {
  return api.post<MaintenanceInfo, MaintenanceForm>('/admin/maintenance', { data, ...options }).then(getData)
}

export interface GetGoogleAuthInfoParameters {
  redirectUrl: string
}
export const getGoogleAuthInfo = (params: GetGoogleAuthInfoParameters, options?: CallerOptions): Promise<AuthInfo> => {
  const { redirectUrl } = params
  return api.get<AuthInfo>('/auth/google', { query: { redirectUrl }, ...options }).then(getData)
}

export const authenticateWithGoogle = (data: GoogleAuthForm, options?: CallerOptions): Promise<AuthenticationResponse> => {
  return api.post<AuthenticationResponse, GoogleAuthForm>('/auth/google/authenticate', { data, ...options }).then(getData)
}

export interface GetClareityAuthInfoParameters {
  redirectUrl: string
}
export const getClareityAuthInfo = (params: GetClareityAuthInfoParameters, options?: CallerOptions): Promise<AuthInfo> => {
  const { redirectUrl } = params
  return api.get<AuthInfo>('/auth/clareity', { query: { redirectUrl }, ...options }).then(getData)
}

export const authenticateWithClareity = (data: ClareityAuthForm, options?: CallerOptions): Promise<AuthenticationResponse> => {
  return api.post<AuthenticationResponse, ClareityAuthForm>('/auth/clareity/authenticate', { data, ...options }).then(getData)
}

export interface GetMicrosoftAuthInfoParameters {
  redirectUrl: string
}
export const getMicrosoftAuthInfo = (params: GetMicrosoftAuthInfoParameters, options?: CallerOptions): Promise<AuthInfo> => {
  const { redirectUrl } = params
  return api.get<AuthInfo>('/auth/microsoft', { query: { redirectUrl }, ...options }).then(getData)
}

export const authenticateWithMicrosoft = (data: MicrosoftAuthForm, options?: CallerOptions): Promise<AuthenticationResponse> => {
  return api.post<AuthenticationResponse, MicrosoftAuthForm>('/auth/microsoft/authenticate', { data, ...options }).then(getData)
}

export interface GetDynaConnectAuthInfoParameters {
  redirectUrl: string
}
export const getDynaConnectAuthInfo = (params: GetDynaConnectAuthInfoParameters, options?: CallerOptions): Promise<AuthInfo> => {
  const { redirectUrl } = params
  return api.get<AuthInfo>('/auth/dynaconnect', { query: { redirectUrl }, ...options }).then(getData)
}

export const authenticateWithDynaConnect = (data: DynaConnectAuthForm, options?: CallerOptions): Promise<AuthenticationResponse> => {
  return api.post<AuthenticationResponse, DynaConnectAuthForm>('/auth/dynaconnect/authenticate', { data, ...options }).then(getData)
}

export const refreshToken = (data: TokenRefreshForm, options?: CallerOptions): Promise<AuthenticationResponse> => {
  return api.post<AuthenticationResponse, TokenRefreshForm>('/auth/token', { data, ...options }).then(getData)
}

export const hashAuth = (data: HashAuthForm, options?: CallerOptions): Promise<AuthenticationResponse> => {
  return api.post<AuthenticationResponse, HashAuthForm>('/auth/hash', { data, ...options }).then(getData)
}

export const createDocument = (data: DocumentForm, options?: CallerOptions): Promise<DocumentCreatedInfo> => {
  return api.post<DocumentCreatedInfo, DocumentForm>('/documents', { data, ...options }).then(getData)
}

export interface GetDocumentInformationParameters {
  id: Id
}
export const getDocumentInformation = (params: GetDocumentInformationParameters, options?: CallerOptions): Promise<DocumentInfo> => {
  const { id } = params
  return api.get<DocumentInfo>(`/documents/${id}`, { ...options }).then(getData)
}

export interface StartDocumentProcessingParameters {
  id: Id
}
export const startDocumentProcessing = (params: StartDocumentProcessingParameters, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.post<void>(`/documents/${id}/process`, { ...options }).then(getData)
}

export interface GetDocumentProcessingStatusParameters {
  id: Id
}
export const getDocumentProcessingStatus = (params: GetDocumentProcessingStatusParameters, options?: CallerOptions): Promise<DocumentProcessingStatus> => {
  const { id } = params
  return api.get<DocumentProcessingStatus>(`/documents/${id}/status`, { ...options }).then(getData)
}

export interface GetDocumentFileParameters {
  id: Id
}
export const getDocumentFile = (params: GetDocumentFileParameters, options?: CallerOptions): Promise<DocumentDownloadUrl> => {
  const { id } = params
  return api.get<DocumentDownloadUrl>(`/documents/${id}/pdf`, { ...options }).then(getData)
}

export interface GetDocumentImageParameters {
  id: Id
  page: number
  quality?: 'low' | 'medium' | 'high' | null
}
export const getDocumentImage = (params: GetDocumentImageParameters, options?: CallerOptions): Promise<DocumentImageUrl> => {
  const { id, page, quality } = params
  return api.get<DocumentImageUrl>(`/documents/${id}/image`, { query: { page, quality }, ...options }).then(getData)
}

export interface GetAccessibleTemplatesParameters {
  query?: string | null
  permissionType?: string | null
  ownerId?: number | null
  teamIds?: string | null
  companyIds?: string | null
  tagsFilter?: string | null
  favoritesFilter?: string | null
  used?: boolean | null
  user?: boolean | null
  owner?: boolean | null
  documents?: boolean | null
  templates?: boolean | null
  overlays?: boolean | null
  transforms?: boolean | null
  stages?: boolean | null
  permissions?: boolean | null
  favorites?: boolean | null
  tags?: boolean | null
  page?: number | null
  count?: number | null
  archived?: boolean | null
  desc?: boolean | null
  sort?: 'default' | 'name' | 'created' | 'updated' | 'permissionType' | 'creator' | 'owner' | 'lastUsed' | 'timesUsed' | null
}
export const getAccessibleTemplates = (params: GetAccessibleTemplatesParameters, options?: CallerOptions): Promise<TemplatesPagedList> => {
  const {
    query,
    permissionType,
    ownerId,
    teamIds,
    companyIds,
    tagsFilter,
    favoritesFilter,
    used,
    user,
    owner,
    documents,
    templates,
    overlays,
    transforms,
    stages,
    permissions,
    favorites,
    tags,
    page,
    count,
    archived,
    desc,
    sort
  } = params
  return api
    .get<TemplatesPagedList>('/templates', {
      query: {
        query,
        permissionType,
        ownerId,
        teamIds,
        companyIds,
        tagsFilter,
        favoritesFilter,
        used,
        user,
        owner,
        documents,
        templates,
        overlays,
        transforms,
        stages,
        permissions,
        favorites,
        tags,
        page,
        count,
        archived,
        desc,
        sort
      },
      ...options
    })
    .then(getData)
}

export const createTemplate = (data: TemplateForm, options?: CallerOptions): Promise<TemplateInfo> => {
  return api.post<TemplateInfo, TemplateForm>('/templates', { data, ...options }).then(getData)
}

export interface GetTemplateTagsParameters {
  query?: string | null
}
export const getTemplateTags = (params: GetTemplateTagsParameters, options?: CallerOptions): Promise<TemplatesTagPagedList> => {
  const { query } = params
  return api.get<TemplatesTagPagedList>('/templates/tags', { query: { query }, ...options }).then(getData)
}

export interface ArchiveTemplateParameters {
  id: number
}
export const archiveTemplate = (params: ArchiveTemplateParameters, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.delete<void>(`/templates/${id}`, { ...options }).then(getData)
}

export interface GetTemplateParameters {
  id: number
  user?: boolean | null
  owner?: boolean | null
  documents?: boolean | null
  templates?: boolean | null
  overlays?: boolean | null
  transforms?: boolean | null
  stages?: boolean | null
  permissions?: boolean | null
  favorites?: boolean | null
  tags?: boolean | null
  archived?: boolean | null
}
export const getTemplate = (params: GetTemplateParameters, options?: CallerOptions): Promise<TemplateInfo> => {
  const { id, user, owner, documents, templates, overlays, transforms, stages, permissions, favorites, tags, archived } = params
  return api.get<TemplateInfo>(`/templates/${id}`, { query: { user, owner, documents, templates, overlays, transforms, stages, permissions, favorites, tags, archived }, ...options }).then(getData)
}

export interface UpdateTemplateParameters {
  id: number
}
export const updateTemplate = (params: UpdateTemplateParameters, data: TemplateForm, options?: CallerOptions): Promise<TemplateInfo> => {
  const { id } = params
  return api.put<TemplateInfo, TemplateForm>(`/templates/${id}`, { data, ...options }).then(getData)
}

export interface DeleteTemplateParameters {
  id: number
}
export const deleteTemplate = (params: DeleteTemplateParameters, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.post<void>(`/templates/${id}/delete`, { ...options }).then(getData)
}

export interface RestoreArchivedTemplateParameters {
  id: number
}
export const restoreArchivedTemplate = (params: RestoreArchivedTemplateParameters, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.post<void>(`/templates/${id}/restore`, { ...options }).then(getData)
}

export interface DuplicateTemplateParameters {
  id: number
}
export const duplicateTemplate = (params: DuplicateTemplateParameters, data: DuplicateTemplateForm, options?: CallerOptions): Promise<TemplateInfo> => {
  const { id } = params
  return api.post<TemplateInfo, DuplicateTemplateForm>(`/templates/${id}/duplicate`, { data, ...options }).then(getData)
}

export interface GetSigningStagesForTemplateParameters {
  id: number
}
export const getSigningStagesForTemplate = (params: GetSigningStagesForTemplateParameters, options?: CallerOptions): Promise<SigningStageInfo[]> => {
  const { id } = params
  return api.get<SigningStageInfo[]>(`/templates/${id}/stages`, { ...options }).then(getData)
}

export interface UpdateSigningStagesForTemplateParameters {
  id: number
}
export const updateSigningStagesForTemplate = (params: UpdateSigningStagesForTemplateParameters, data: SigningStageForm[], options?: CallerOptions): Promise<SigningStageInfo[]> => {
  const { id } = params
  return api.post<SigningStageInfo[], SigningStageForm[]>(`/templates/${id}/stages`, { data, ...options }).then(getData)
}

export interface GetTemplatePdfParameters {
  id: number
  redirect?: boolean | null
}
export const getTemplatePdf = (params: GetTemplatePdfParameters, options?: CallerOptions): Promise<DocumentDownloadUrl> => {
  const { id, redirect } = params
  return api.get<DocumentDownloadUrl>(`/templates/${id}/pdf`, { query: { redirect }, ...options }).then(getData)
}

export interface UpdateTemplateSharingSettingsParameters {
  id: number
}
export const updateTemplateSharingSettings = (params: UpdateTemplateSharingSettingsParameters, data: TemplatePermissionsForm, options?: CallerOptions): Promise<TemplatePermissionList> => {
  const { id } = params
  return api.put<TemplatePermissionList, TemplatePermissionsForm>(`/templates/${id}/permissions`, { data, ...options }).then(getData)
}

export interface UpdateTemplateFavoritesSettingsParameters {
  id: number
}
export const updateTemplateFavoritesSettings = (params: UpdateTemplateFavoritesSettingsParameters, data: TemplateFavoritesForm, options?: CallerOptions): Promise<TemplateFavoritesInfo> => {
  const { id } = params
  return api.put<TemplateFavoritesInfo, TemplateFavoritesForm>(`/templates/${id}/favorites`, { data, ...options }).then(getData)
}

export interface GetSigningsParameters {
  query?: string | null
  mlsId?: string | null
  address?: string | null
  contactId?: number | null
  status?: string | null
  contact?: string | null
  user?: string | null
  group?: boolean | null
  groupId?: number | null
  senderId?: number | null
  days?: number | null
  documents?: boolean | null
  templates?: boolean | null
  overlays?: boolean | null
  transforms?: boolean | null
  stages?: boolean | null
  currentStage?: boolean | null
  participants?: boolean | null
  events?: boolean | null
  contacts?: boolean | null
  sender?: boolean | null
  page?: number | null
  count?: number | null
  archived?: boolean | null
  desc?: boolean | null
  sort?: 'created' | 'name' | 'documents' | 'stage' | 'progress' | 'updated' | 'comments' | 'expiry' | 'status' | 'sender' | null
}
export const getSignings = (params: GetSigningsParameters, options?: CallerOptions): Promise<SigningPagedList> => {
  const {
    query,
    mlsId,
    address,
    contactId,
    status,
    contact,
    user,
    group,
    groupId,
    senderId,
    days,
    documents,
    templates,
    overlays,
    transforms,
    stages,
    currentStage,
    participants,
    events,
    contacts,
    sender,
    page,
    count,
    archived,
    desc,
    sort
  } = params
  return api
    .get<SigningPagedList>('/signings', {
      query: {
        query,
        mlsId,
        address,
        contactId,
        status,
        contact,
        user,
        group,
        groupId,
        senderId,
        days,
        documents,
        templates,
        overlays,
        transforms,
        stages,
        currentStage,
        participants,
        events,
        contacts,
        sender,
        page,
        count,
        archived,
        desc,
        sort
      },
      ...options
    })
    .then(getData)
}

export const createSigning = (data: SigningForm, options?: CallerOptions): Promise<SigningInfo> => {
  return api.post<SigningInfo, SigningForm>('/signings', { data, ...options }).then(getData)
}

export interface ArchiveSigningParameters {
  id: number
}
export const archiveSigning = (params: ArchiveSigningParameters, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.delete<void>(`/signings/${id}`, { ...options }).then(getData)
}

export interface GetSigningParameters {
  id: number
  documents?: boolean | null
  templates?: boolean | null
  overlays?: boolean | null
  transforms?: boolean | null
  stages?: boolean | null
  currentStage?: boolean | null
  participants?: boolean | null
  events?: boolean | null
  group?: boolean | null
  sender?: boolean | null
  contacts?: boolean | null
  comments?: boolean | null
}
export const getSigning = (params: GetSigningParameters, options?: CallerOptions): Promise<SigningInfo> => {
  const { id, documents, templates, overlays, transforms, stages, currentStage, participants, events, group, sender, contacts, comments } = params
  return api
    .get<SigningInfo>(`/signings/${id}`, { query: { documents, templates, overlays, transforms, stages, currentStage, participants, events, group, sender, contacts, comments }, ...options })
    .then(getData)
}

export interface UpdateSigningParameters {
  id: number
  rev?: string | null
}
export const updateSigning = (params: UpdateSigningParameters, data: SigningForm, options?: CallerOptions): Promise<SigningInfo> => {
  const { id, rev } = params
  return api.put<SigningInfo, SigningForm>(`/signings/${id}`, { query: { rev }, data, ...options }).then(getData)
}

export interface StartSigningParameters {
  id: number
  rev?: string | null
}
export const startSigning = (params: StartSigningParameters, data: SigningData, options?: CallerOptions): Promise<void> => {
  const { id, rev } = params
  return api.post<void, SigningData>(`/signings/${id}/start`, { query: { rev }, data, ...options }).then(getData)
}

export interface WithdrawSigningParameters {
  id: number
}
export const withdrawSigning = (params: WithdrawSigningParameters, data: WithdrawSigningForm, options?: CallerOptions): Promise<SigningInfo> => {
  const { id } = params
  return api.post<SigningInfo, WithdrawSigningForm>(`/signings/${id}/withdraw`, { data, ...options }).then(getData)
}

export interface ForwardSigningParameters {
  id: number
}
export const forwardSigning = (params: ForwardSigningParameters, data: ForwardSigningForm, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.post<void, ForwardSigningForm>(`/signings/${id}/forward`, { data, ...options }).then(getData)
}

export interface DuplicateSigningParameters {
  id: number
}
export const duplicateSigning = (params: DuplicateSigningParameters, data: DuplicateSigningForm, options?: CallerOptions): Promise<SigningInfo> => {
  const { id } = params
  return api.post<SigningInfo, DuplicateSigningForm>(`/signings/${id}/duplicate`, { data, ...options }).then(getData)
}

export interface ResendSigningParameters {
  id: number
  participantId?: Id1 | null
}
export const resendSigning = (params: ResendSigningParameters, options?: CallerOptions): Promise<void> => {
  const { id, participantId } = params
  return api.post<void>(`/signings/${id}/resend`, { query: { participantId }, ...options }).then(getData)
}

export interface DeleteSigningParameters {
  id: number
}
export const deleteSigning = (params: DeleteSigningParameters, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.post<void>(`/signings/${id}/delete`, { ...options }).then(getData)
}

export interface UpdateSigningParticipantParameters {
  id: number
  participantId: Id1
  rev?: string | null
}
export const updateSigningParticipant = (params: UpdateSigningParticipantParameters, data: ParticipantForm, options?: CallerOptions): Promise<ParticipantInfo> => {
  const { id, participantId, rev } = params
  return api.post<ParticipantInfo, ParticipantForm>(`/signings/${id}/participants/${participantId}`, { query: { rev }, data, ...options }).then(getData)
}

export interface ListCommentsForSigningParameters {
  id: number
  page?: Page | null
}
export const listCommentsForSigning = (params: ListCommentsForSigningParameters, options?: CallerOptions): Promise<SigningCommentPagedList> => {
  const { id, page } = params
  return api.get<SigningCommentPagedList>(`/signings/${id}/comments`, { query: { page }, ...options }).then(getData)
}

export interface AddCommentToSigningParameters {
  id: number
}
export const addCommentToSigning = (params: AddCommentToSigningParameters, data: SigningCommentForm, options?: CallerOptions): Promise<SigningCommentInfo> => {
  const { id } = params
  return api.post<SigningCommentInfo, SigningCommentForm>(`/signings/${id}/comments`, { data, ...options }).then(getData)
}

export interface DeleteCommentParameters {
  id: number
  commentId: number
}
export const deleteComment = (params: DeleteCommentParameters, options?: CallerOptions): Promise<void> => {
  const { id, commentId } = params
  return api.delete<void>(`/signings/${id}/comments/${commentId}`, { ...options }).then(getData)
}

export interface GetSigningInfoForParticipantParameters {
  id: number
}
export const getSigningInfoForParticipant = (params: GetSigningInfoForParticipantParameters, options?: CallerOptions): Promise<SigningReviewInfo> => {
  const { id } = params
  return api.get<SigningReviewInfo>(`/signings/${id}/review`, { ...options }).then(getData)
}

export interface SendPhoneVerificationCodeParameters {
  id: number
}
export const sendPhoneVerificationCode = (params: SendPhoneVerificationCodeParameters, options?: CallerOptions): Promise<PhoneVerificationResponse> => {
  const { id } = params
  return api.post<PhoneVerificationResponse>(`/signings/${id}/verification`, { ...options }).then(getData)
}

export interface VerifySmsCodeParameters {
  id: number
}
export const verifySmsCode = (params: VerifySmsCodeParameters, data: SmsVerificationForm, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.post<void, SmsVerificationForm>(`/signings/${id}/verify`, { data, ...options }).then(getData)
}

export interface TrackViewedParameters {
  id: number
}
export const trackViewed = (params: TrackViewedParameters, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.post<void>(`/signings/${id}/view`, { ...options }).then(getData)
}

export interface GiveConsentParameters {
  id: number
}
export const giveConsent = (params: GiveConsentParameters, data: ConsentForm, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.post<void, ConsentForm>(`/signings/${id}/consent`, { data, ...options }).then(getData)
}

export interface SignOffParameters {
  id: number
}
export const signOff = (params: SignOffParameters, data: SigningData, options?: CallerOptions): Promise<SigningCompletedInfo> => {
  const { id } = params
  return api.post<SigningCompletedInfo, SigningData>(`/signings/${id}/sign`, { data, ...options }).then(getData)
}

export interface RejectSigningParameters {
  id: number
}
export const rejectSigning = (params: RejectSigningParameters, data: RejectSigningForm, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.post<void, RejectSigningForm>(`/signings/${id}/reject`, { data, ...options }).then(getData)
}

export interface StartOverSigningParameters {
  id: number
}
export const startOverSigning = (params: StartOverSigningParameters, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.post<void>(`/signings/${id}/startover`, { ...options }).then(getData)
}

export interface GetSigningDataParameters {
  id: number
}
export const getSigningData = (params: GetSigningDataParameters, options?: CallerOptions): Promise<SigningData> => {
  const { id } = params
  return api.get<SigningData>(`/signings/${id}/data`, { ...options }).then(getData)
}

export interface SaveSigningDataParameters {
  id: number
}
export const saveSigningData = (params: SaveSigningDataParameters, data: SigningData, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.post<void, SigningData>(`/signings/${id}/data`, { data, ...options }).then(getData)
}

export interface GetSigningEventsParameters {
  id: number
  types?: string | null
  stageId?: number | null
  from?: string | null
  to?: string | null
  stage?: boolean | null
  page?: number | null
  count?: number | null
  sort?: 'created' | 'type' | 'stage' | null
  desc?: boolean | null
}
export const getSigningEvents = (params: GetSigningEventsParameters, options?: CallerOptions): Promise<EventPagedList> => {
  const { id, types, stageId, from, to, stage, page, count, sort, desc } = params
  return api.get<EventPagedList>(`/signings/${id}/events`, { query: { types, stageId, from, to, stage, page, count, sort, desc }, ...options }).then(getData)
}

export interface GetSigningPdfParameters {
  id: number
  redirect?: boolean | null
}
export const getSigningPdf = (params: GetSigningPdfParameters, options?: CallerOptions): Promise<DocumentDownloadUrl> => {
  const { id, redirect } = params
  return api.get<DocumentDownloadUrl>(`/signings/${id}/pdf`, { query: { redirect }, ...options }).then(getData)
}

export interface GetSigningZipParameters {
  id: number
  redirect?: boolean | null
}
export const getSigningZip = (params: GetSigningZipParameters, options?: CallerOptions): Promise<DocumentDownloadUrl> => {
  const { id, redirect } = params
  return api.get<DocumentDownloadUrl>(`/signings/${id}/zip`, { query: { redirect }, ...options }).then(getData)
}

export interface ListSigningPropertyAddressesParameters {
  query?: string | null
  page?: number | null
  count?: number | null
}
export const listSigningPropertyAddresses = (params: ListSigningPropertyAddressesParameters, options?: CallerOptions): Promise<SigningAddressPagedList> => {
  const { query, page, count } = params
  return api.get<SigningAddressPagedList>('/signings/addresses', { query: { query, page, count }, ...options }).then(getData)
}

export interface GetCompaniesParameters {
  query?: string | null
  name?: string | null
  havingShortName?: boolean | null
  havingTemplates?: boolean | null
  type?: CompanyType | null
  page?: number | null
  count?: number | null
  archived?: boolean | null
  desc?: boolean | null
  sort?: 'name' | 'type' | null
}
export const getCompanies = (params: GetCompaniesParameters, options?: CallerOptions): Promise<CompanyPagedList> => {
  const { query, name, havingShortName, havingTemplates, type, page, count, archived, desc, sort } = params
  return api.get<CompanyPagedList>('/companies', { query: { query, name, havingShortName, havingTemplates, type, page, count, archived, desc, sort }, ...options }).then(getData)
}

export interface GetContactsParameters {
  query?: string | null
  name?: string | null
  email?: string | null
  phone?: string | null
  groups?: boolean | null
  groupId?: number | null
  ownerId?: number | null
  user?: boolean | null
  owner?: boolean | null
  page?: number | null
  count?: number | null
  archived?: boolean | null
  desc?: boolean | null
  sort?: 'name' | 'email' | 'phone' | 'created' | 'creator' | 'owner' | 'company' | null
}
export const getContacts = (params: GetContactsParameters, options?: CallerOptions): Promise<ContactPagedList> => {
  const { query, name, email, phone, groups, groupId, ownerId, user, owner, page, count, archived, desc, sort } = params
  return api.get<ContactPagedList>('/contacts', { query: { query, name, email, phone, groups, groupId, ownerId, user, owner, page, count, archived, desc, sort }, ...options }).then(getData)
}

export const createContact = (data: ContactForm, options?: CallerOptions): Promise<ContactInfo> => {
  return api.post<ContactInfo, ContactForm>('/contacts', { data, ...options }).then(getData)
}

export const bulkUpdateContacts = (data: BulkUpdateContactForm, options?: CallerOptions): Promise<ContactInfo[]> => {
  return api.post<ContactInfo[], BulkUpdateContactForm>('/contacts/bulk', { data, ...options }).then(getData)
}

export interface GetContactCompaniesParameters {
  query?: string | null
  ownerId?: number | null
  page?: number | null
  count?: number | null
}
export const getContactCompanies = (params: GetContactCompaniesParameters, options?: CallerOptions): Promise<ContactCompanyPagedList> => {
  const { query, ownerId, page, count } = params
  return api.get<ContactCompanyPagedList>('/contacts/companies', { query: { query, ownerId, page, count }, ...options }).then(getData)
}

export interface ArchiveContactParameters {
  id: number
}
export const archiveContact = (params: ArchiveContactParameters, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.delete<void>(`/contacts/${id}`, { ...options }).then(getData)
}

export interface GetContactParameters {
  id: number
  groups?: boolean | null
  user?: boolean | null
  owner?: boolean | null
  archived?: boolean | null
}
export const getContact = (params: GetContactParameters, options?: CallerOptions): Promise<ContactInfo> => {
  const { id, groups, user, owner, archived } = params
  return api.get<ContactInfo>(`/contacts/${id}`, { query: { groups, user, owner, archived }, ...options }).then(getData)
}

export interface UpdateContactParameters {
  id: number
}
export const updateContact = (params: UpdateContactParameters, data: ContactForm, options?: CallerOptions): Promise<ContactInfo> => {
  const { id } = params
  return api.put<ContactInfo, ContactForm>(`/contacts/${id}`, { data, ...options }).then(getData)
}

export interface DeleteContactParameters {
  id: number
}
export const deleteContact = (params: DeleteContactParameters, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.post<void>(`/contacts/${id}/delete`, { ...options }).then(getData)
}

export interface RestoreArchivedContactParameters {
  id: number
}
export const restoreArchivedContact = (params: RestoreArchivedContactParameters, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.post<void>(`/contacts/${id}/restore`, { ...options }).then(getData)
}

export interface GetContactGroupsParameters {
  query?: string | null
  items?: boolean | null
  ownerId?: number | null
  user?: boolean | null
  owner?: boolean | null
  page?: number | null
  count?: number | null
  archived?: boolean | null
  desc?: boolean | null
  sort?: 'name' | 'created' | 'creator' | 'owner' | null
}
export const getContactGroups = (params: GetContactGroupsParameters, options?: CallerOptions): Promise<ContactGroupPagedList> => {
  const { query, items, ownerId, user, owner, page, count, archived, desc, sort } = params
  return api.get<ContactGroupPagedList>('/contacts/groups', { query: { query, items, ownerId, user, owner, page, count, archived, desc, sort }, ...options }).then(getData)
}

export const createContactGroup = (data: ContactGroupForm, options?: CallerOptions): Promise<ContactGroupInfo> => {
  return api.post<ContactGroupInfo, ContactGroupForm>('/contacts/groups', { data, ...options }).then(getData)
}

export interface ArchiveContactGroupParameters {
  id: number
}
export const archiveContactGroup = (params: ArchiveContactGroupParameters, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.delete<void>(`/contacts/groups/${id}`, { ...options }).then(getData)
}

export interface GetContactGroupParameters {
  id: number
  items?: boolean | null
  user?: boolean | null
  owner?: boolean | null
  archived?: boolean | null
}
export const getContactGroup = (params: GetContactGroupParameters, options?: CallerOptions): Promise<ContactGroupInfo> => {
  const { id, items, user, owner, archived } = params
  return api.get<ContactGroupInfo>(`/contacts/groups/${id}`, { query: { items, user, owner, archived }, ...options }).then(getData)
}

export interface UpdateContactGroupParameters {
  id: number
}
export const updateContactGroup = (params: UpdateContactGroupParameters, data: ContactGroupForm, options?: CallerOptions): Promise<ContactGroupInfo> => {
  const { id } = params
  return api.put<ContactGroupInfo, ContactGroupForm>(`/contacts/groups/${id}`, { data, ...options }).then(getData)
}

export interface DeleteContactGroupParameters {
  id: number
}
export const deleteContactGroup = (params: DeleteContactGroupParameters, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.post<void>(`/contacts/groups/${id}/delete`, { ...options }).then(getData)
}

export interface RestoreArchivedContactGroupParameters {
  id: number
}
export const restoreArchivedContactGroup = (params: RestoreArchivedContactGroupParameters, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.post<void>(`/contacts/groups/${id}/restore`, { ...options }).then(getData)
}

export interface BulkUpdateContactsInGroupParameters {
  id: number
}
export const bulkUpdateContactsInGroup = (params: BulkUpdateContactsInGroupParameters, data: BulkUpdateContactsInGroupRequest[], options?: CallerOptions): Promise<ContactGroupItemInfo[]> => {
  const { id } = params
  return api.post<ContactGroupItemInfo[], BulkUpdateContactsInGroupRequest[]>(`/contacts/groups/${id}/items`, { data, ...options }).then(getData)
}

export interface AddContactToGroupParameters {
  id: number
}
export const addContactToGroup = (params: AddContactToGroupParameters, data: ContactGroupItemForm, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.put<void, ContactGroupItemForm>(`/contacts/groups/${id}/items`, { data, ...options }).then(getData)
}

export interface DeleteContactFromGroupParameters {
  id: number
  contactId: number
}
export const deleteContactFromGroup = (params: DeleteContactFromGroupParameters, options?: CallerOptions): Promise<void> => {
  const { id, contactId } = params
  return api.delete<void>(`/contacts/groups/${id}/items/${contactId}`, { ...options }).then(getData)
}

export interface GetTeamsParameters {
  query?: string | null
  lead?: boolean | null
  page?: number | null
  count?: number | null
}
export const getTeams = (params: GetTeamsParameters, options?: CallerOptions): Promise<TeamsPagedList> => {
  const { query, lead, page, count } = params
  return api.get<TeamsPagedList>('/teams', { query: { query, lead, page, count }, ...options }).then(getData)
}

export interface GetUsersParameters {
  query?: string | null
  role?: string | null
  name?: string | null
  assisting?: boolean | null
  assistants?: boolean | null
  includeMe?: boolean | null
  photo?: boolean | null
  accessGranted?: boolean | null
  special?: boolean | null
  templateId?: number | null
  templateIds?: string | null
  permissions?: boolean | null
  page?: number | null
  count?: number | null
  archived?: boolean | null
  desc?: boolean | null
  sort?: 'name' | 'last' | 'role' | 'email' | 'phone' | 'created' | null
}
export const getUsers = (params: GetUsersParameters, options?: CallerOptions): Promise<UsersPagedList> => {
  const { query, role, name, assisting, assistants, includeMe, photo, accessGranted, special, templateId, templateIds, permissions, page, count, archived, desc, sort } = params
  return api
    .get<UsersPagedList>('/users', {
      query: { query, role, name, assisting, assistants, includeMe, photo, accessGranted, special, templateId, templateIds, permissions, page, count, archived, desc, sort },
      ...options
    })
    .then(getData)
}

export interface GetMyProfileParameters {
  companies?: boolean | null
  permissions?: boolean | null
  loadAssisting?: boolean | null
  photo?: boolean | null
}
export const getMyProfile = (params: GetMyProfileParameters, options?: CallerOptions): Promise<UserInfo> => {
  const { companies, permissions, loadAssisting, photo } = params
  return api.get<UserInfo>('/users/me', { query: { companies, permissions, loadAssisting, photo }, ...options }).then(getData)
}

export const acceptTermsOfService = (options?: CallerOptions): Promise<void> => {
  return api.post<void>('/users/terms', { ...options }).then(getData)
}

export interface UpdateUserPermissionsParameters {
  id: number
}
export const updateUserPermissions = (params: UpdateUserPermissionsParameters, data: UserSpecialPermissionForm, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.put<void, UserSpecialPermissionForm>(`/users/${id}/permissions`, { data, ...options }).then(getData)
}

export const getTemplateRolesDictionary = (options?: CallerOptions): Promise<RoleInfo[]> => {
  return api.get<RoleInfo[]>('/settings/templates/roles', { ...options }).then(getData)
}

export interface GetNotificationSettingsParameters {
  ownerId?: number | null
}
export const getNotificationSettings = (params: GetNotificationSettingsParameters, options?: CallerOptions): Promise<NotificationSettingsInfo> => {
  const { ownerId } = params
  return api.get<NotificationSettingsInfo>('/settings/notifications', { query: { ownerId }, ...options }).then(getData)
}

export const saveNotificationSettings = (data: NotificationSettingsForm, options?: CallerOptions): Promise<NotificationSettingsInfo> => {
  return api.post<NotificationSettingsInfo, NotificationSettingsForm>('/settings/notifications', { data, ...options }).then(getData)
}

export const deleteLogo = (options?: CallerOptions): Promise<void> => {
  return api.delete<void>('/settings/email/logo', { ...options }).then(getData)
}

export const getUploadedLogoInfo = (options?: CallerOptions): Promise<UploadedLogoInfo> => {
  return api.get<UploadedLogoInfo>('/settings/email/logo', { ...options }).then(getData)
}

export const uploadNewLogo = (data: UploadLogoForm, options?: CallerOptions): Promise<UploadLogoInfo> => {
  return api.post<UploadLogoInfo, UploadLogoForm>('/settings/email/logo', { data, ...options }).then(getData)
}

export const getEmailAppearanceSettings = (options?: CallerOptions): Promise<EmailAppearanceSettingsInfo> => {
  return api.get<EmailAppearanceSettingsInfo>('/settings/email/appearance', { ...options }).then(getData)
}

export const saveEmailAppearanceSettings = (data: EmailAppearanceSettingsForm, options?: CallerOptions): Promise<EmailAppearanceSettingsInfo> => {
  return api.post<EmailAppearanceSettingsInfo, EmailAppearanceSettingsForm>('/settings/email/appearance', { data, ...options }).then(getData)
}

export const getEmailAppearancePreview = (data: GetEmailAppearancePreviewRequest, options?: CallerOptions): Promise<void> => {
  return api.post<void, GetEmailAppearancePreviewRequest>('/settings/email/preview', { data, ...options }).then(getData)
}

export const getOverlayDefaults = (options?: CallerOptions): Promise<OverlayDefaultSettings[]> => {
  return api.get<OverlayDefaultSettings[]>('/settings/overlays/defaults', { ...options }).then(getData)
}

export const saveOverlayDefaults = (data: OverlayDefaultSettings[], options?: CallerOptions): Promise<void> => {
  return api.post<void, OverlayDefaultSettings[]>('/settings/overlays/defaults', { data, ...options }).then(getData)
}

export interface ListSavedInstructionsParameters {
  ownerId?: number | null
  page?: number | null
  count?: number | null
  desc?: boolean | null
  sort?: 'title' | 'created' | null
  query?: string | null
}
export const listSavedInstructions = (params: ListSavedInstructionsParameters, options?: CallerOptions): Promise<SavedInstructionsPagedList> => {
  const { ownerId, page, count, desc, sort, query } = params
  return api.get<SavedInstructionsPagedList>('/instructions', { query: { ownerId, page, count, desc, sort, query }, ...options }).then(getData)
}

export const createSavedInstructions = (data: SavedInstructionsForm, options?: CallerOptions): Promise<SavedInstructionsInfo> => {
  return api.post<SavedInstructionsInfo, SavedInstructionsForm>('/instructions', { data, ...options }).then(getData)
}

export interface DeleteSavedInstructionsParameters {
  id: number
}
export const deleteSavedInstructions = (params: DeleteSavedInstructionsParameters, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.delete<void>(`/instructions/${id}`, { ...options }).then(getData)
}

export interface UpdateSavedInstructionsParameters {
  id: number
}
export const updateSavedInstructions = (params: UpdateSavedInstructionsParameters, data: SavedInstructionsForm, options?: CallerOptions): Promise<SavedInstructionsInfo> => {
  const { id } = params
  return api.put<SavedInstructionsInfo, SavedInstructionsForm>(`/instructions/${id}`, { data, ...options }).then(getData)
}

export interface ListClausesParameters {
  ownerId?: number | null
  page?: number | null
  count?: number | null
  sort?: 'title' | 'created' | null
  query?: string | null
  desc?: boolean | null
}
export const listClauses = (params: ListClausesParameters, options?: CallerOptions): Promise<ClausePagedList> => {
  const { ownerId, page, count, sort, query, desc } = params
  return api.get<ClausePagedList>('/clauses', { query: { ownerId, page, count, sort, query, desc }, ...options }).then(getData)
}

export const createClause = (data: ClauseForm, options?: CallerOptions): Promise<ClauseInfo> => {
  return api.post<ClauseInfo, ClauseForm>('/clauses', { data, ...options }).then(getData)
}

export interface DeleteClauseParameters {
  id: number
}
export const deleteClause = (params: DeleteClauseParameters, options?: CallerOptions): Promise<void> => {
  const { id } = params
  return api.delete<void>(`/clauses/${id}`, { ...options }).then(getData)
}

export interface UpdateClauseParameters {
  id: number
}
export const updateClause = (params: UpdateClauseParameters, data: ClauseForm, options?: CallerOptions): Promise<ClauseInfo> => {
  const { id } = params
  return api.put<ClauseInfo, ClauseForm>(`/clauses/${id}`, { data, ...options }).then(getData)
}
