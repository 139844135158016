import { i18n } from '@/modules/i18n'
import { rollbar } from '@/modules/rollbar'
import { notifyGlobal } from '@/ui/Notifications/notify'
import { ApiResponseInterceptor, RequestOptions, ResponseWithData } from '@/utils/createApi'

const NotifiableHttpErrorCodes = [400, 401, 403, 404, 500]

/**
 * Intercepts api error of certain http codes, reports it to rollbar and notify User about problem
 */
export const httpErrorInterceptor: ApiResponseInterceptor = (res: ResponseWithData<any>, options: RequestOptions) => {
  const { notify } = notifyGlobal
  const t = i18n?.global.t as (msg: string, options?: Record<string, string | number>) => string

  const isNotifiableCode = NotifiableHttpErrorCodes.includes(res.status)
  const isSuppressedCode = options.suppressedCodes?.includes(res.status)

  if (rollbar && t && notify && isNotifiableCode && !isSuppressedCode) {
    const errorItem = res.status === 500
      ? `Server responded with ${res.status} error code. URL: ${res.url}`
      : res.data?.message ?? res.data

    const item = rollbar?.error(errorItem, res)

    notify({
      duration: 12000,
      semantics: 'error',
      message: t('sorry-were-experiencing-issue-please-contact-support-for-help-the-issue-reference-is-ref', {
        ref: item?.uuid ?? 'undefined'
      })
    })
  }

  return res
}
