import PortalVue from 'portal-vue'
import VueGtag from 'vue-gtag'

import { initApp } from '@/initApp'
import { initWidget } from '@/initWidget'
import { gtagTrackDirective } from '@/utils/gtagTrackDirective'
// import { mockApi } from '@/api/apiMocks'

import '@unocss/reset/tailwind.css'
import '@/styles/main.css'
import 'uno.css'

const startApp = async () => {
  // Launch widget app
  const isWidget = import.meta.env.VITE_BUILD_WIDGET === 'true'
  if (isWidget) {
    const app = createApp(defineAsyncComponent(() => import('@/pages/Widget.vue')))
    const { makeRouter } = await import('@/router/makeWidgetRouter')
    const router = makeRouter()

    app.use(router)
    initWidget({ app, router, initialState: window.__INITIAL_STATE__ ?? {} })
    router.isReady().then(() => app.mount('#app'))

    return
  }

  // Launch admin app
  const isAdmin = import.meta.env.VITE_BUILD_ADMIN === 'true'
  if (isAdmin) {
    const app = createApp(defineAsyncComponent(() => import('@/Main.vue')))
    const { makeRouter } = await import('@/router/makeAdminRouter')
    const router = makeRouter()

    app.use(router)
    initApp({ app, router, initialState: window.__INITIAL_STATE__ ?? {} })
    app.use(PortalVue)
    router.isReady().then(() => app.mount('#app'))

    return
  }

  // Launch regular mode(management) app
  const app = createApp(defineAsyncComponent(() => import('@/Main.vue')))
  const { makeRouter } = await import('@/router/makeRouter')
  const router = makeRouter()
  app.use(router)
  initApp({ app, router, initialState: window.__INITIAL_STATE__ ?? {} })
  app.use(PortalVue)

  const googleAnalyticsID = import.meta.env.VITE_GOOGLE_ANALYTICS_MANAGEMENT_KEY
  if (googleAnalyticsID) {
    app.use(VueGtag, { config: { id: googleAnalyticsID } }, router)
  }
  app.directive('gtag', gtagTrackDirective)

  // wait until router is ready before mounting to ensure hydration match
  router.isReady().then(() => {
    // mockApi()
    app.mount('#app')
  })
}

if (import.meta.env.VITE_FORCE_HTTPS === 'true' && location.protocol !== 'https:') {
  location.protocol = 'https:'
} else {
  setTimeout(startApp)
}
