import Rollbar from 'rollbar'

// eslint-disable-next-line import/no-default-export
export default {
  accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: import.meta.env.VITE_ROLLBAR_ENV,
    client: {
      javascript: {
        source_map_enabled: import.meta.env.VITE_USE_SOURCE_MAP === 'true',
        code_version: import.meta.env.VITE_ROLLBAR_COMMIT ?? '1.0.0'
      }
    }
  }
} as Rollbar.Configuration
