import Rollbar, { LogArgument } from 'rollbar'
import { App, ComponentPublicInstance } from 'vue'

import { UserModule } from '@/UserModule'
import { isApiResponseError } from '@/use/useErrorMapper'

import config from '../../rollbar.config'

const RB_TOKEN = import.meta.env.VITE_ROLLBAR_TOKEN

export const rollbar = RB_TOKEN ? new Rollbar(config) : null

const RollbarPlugin = {
  install: (app: App) => {
    if (!rollbar) return

    app.config.errorHandler = (error, vm: ComponentPublicInstance | null, info) => {
      const component = vm?.$?.type?.__name
      const vueExtraArg = {
        vue: { component, info }
      }

      if (isApiResponseError(error)) {
        rollbar.error(error.data.message, error.data, vueExtraArg)
      } else {
        rollbar.error(error as LogArgument, vueExtraArg)
      }

      console.error(error)
    }

    app.provide('rollbar', rollbar)
  }
}

export const install: UserModule = ({ app }) => {
  app.use(RollbarPlugin)
}
