import { LocationQuery, RouteLocationNormalized } from 'vue-router'

import { hashAuth } from '@/api/apiCalls'
import { accessToken, refreshToken } from '@/modules/auth'

export const isRequiredHashAuthParamsInQuery = (query: LocationQuery): boolean => {
  const { ud, hs, ts } = query
  return [ud, hs, ts].every(Boolean)
}

/**
 * HashAuth based on route query params
 */
export const hashAuthWithQuery = async (to: RouteLocationNormalized) => {
  if (!isRequiredHashAuthParamsInQuery(to.query)) {
    return Promise.reject('[hashAuthWithQuery] error, missing some required params')
  }

  const { ud, hs, ts } = to.query
  const { refreshToken: refreshTokenHashAuth, accessToken: accessTokenHashAuth } = await hashAuth({
    ud: String(ud),
    ts: String(ts),
    hs: String(hs)
  })

  refreshToken.value = refreshTokenHashAuth
  accessToken.value = accessTokenHashAuth

  return to
}

/**
 * Remove "hashAuth" params from route query
 */
export const purifyHashAuthQueryParams = (to: RouteLocationNormalized) => {
  // eslint-disable-next-line @typescript-eslint/no-shadow,unused-imports/no-unused-vars-ts
  const { ud, hs, ts, ...queryParams } = to.query

  return Promise.resolve({ ...to, query: queryParams })
}
