import { Loader } from '@googlemaps/js-api-loader'
import { InjectionKey, Ref } from 'vue'

import { UserModule } from '@/UserModule'

type GPlacesService = google.maps.places.PlacesService
type GPlacesInjectionKey = InjectionKey<Readonly<Ref<GPlacesService>>>
type GPlacesAutocompleteService = google.maps.places.AutocompleteService
type GPlacesAutocompleteInjectionKey = InjectionKey<Readonly<Ref<GPlacesAutocompleteService>>>

const googlePlacesApiInjectionKey: GPlacesInjectionKey = Symbol('googlePLacesApiInjectionKey')
const googlePlacesAutocompleteApiInjectionKey: GPlacesAutocompleteInjectionKey = Symbol('googlePLacesAutocompleteApiInjectionKey')

export const install: UserModule = async ({ app }) => {
  const loader = new Loader({
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    version: 'weekly',
    libraries: ['places']
  })

  const placesApi = await loader.importLibrary('places')
  const placesService = ref<GPlacesService>(
    new placesApi.PlacesService(document.createElement('div'))
  )
  const autocompleteService = ref<GPlacesAutocompleteService>(
    new placesApi.AutocompleteService()
  )

  app.provide(googlePlacesApiInjectionKey, computed(() => placesService.value))
  app.provide(googlePlacesAutocompleteApiInjectionKey, computed(() => autocompleteService.value))
}

export const useGooglePlacesApi = () => inject(googlePlacesApiInjectionKey)
export const useGooglePlacesAutocompleteApi = () => inject(googlePlacesAutocompleteApiInjectionKey)
