import { InjectionKey, Ref } from 'vue'

import type { UserModule } from '@/UserModule'
import { loadScript } from '@/utils/utils'

export interface UsersnapInitOptions {
  /** An object holding custom data. This data will be passed along with every feedback */
  custom?: Record<string, any>
  /** An object holding information about the current user */
  user?: {
    /** Will be pre-filled in the widget and attached to every feedback */
    email?: string
    /** Can be used for determining whether to show a widget or not */
    userId?: string
  }
  /** Language in which widgets should be displayed */
  locale?: string
  /** Can be used to disable loading external fonts. Defaults to false */
  useSystemFonts?: boolean
  /** Can be used to disable usage of localStorage. Defaults to true */
  useLocalStorage?: boolean
  /** Can be used to enable the usage of the native screenshot feature. Defaults to false */
  nativeScreenshot?: boolean
  /** can be used to disable the collection of IP and geo location. Defaults to 'all' */
  collectGeoLocation?: 'all' | 'none'
}

export interface UsersnapGlobalApi {
  init: (options: UsersnapInitOptions) => Promise<void>
  destroy: () => Promise<void>
  logEvent: (eventName: string) => Promise<void>
  on: (eventName: string, callback: (event: any) => void) => void
  off: (eventName: string, callback: (event: any) => void) => void
}

const usersnapInjectionKey: InjectionKey<Readonly<Ref<UsersnapGlobalApi>>> = Symbol('usersnapInjectionKey')

export const install: UserModule = ({ app }) => {
  const usersnapApi = ref<UsersnapGlobalApi>()

  if (import.meta.env.VITE_USERSNAP_GLOBAL_API_KEY && !import.meta.env.DEV) {
    (window as any).onUsersnapCXLoad = (api: UsersnapGlobalApi) => {
      usersnapApi.value = api
      delete (window as any).onUsersnapCXLoad
    }

    loadScript(`https://widget.usersnap.com/global/load/${import.meta.env.VITE_USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad`)
  } else {
    usersnapApi.value = {
      init: (options: UsersnapInitOptions) => Promise.resolve(),
      destroy: () => Promise.resolve(),
      logEvent: (eventName: string) => Promise.resolve(),
      on: (eventName: string, callback: (event: any) => void) => undefined,
      off: (eventName: string, callback: (event: any) => void) => undefined
    }
  }

  app.provide(usersnapInjectionKey, computed(() => usersnapApi.value!))
}

export const useUsersnapApi = () => inject(usersnapInjectionKey)!
